import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../theme'

const ItemList = ({ items, nbGuest, ...props }) => {
  return (
    <StyledItemList {...props}>
      <div className="wrapper">
        <div className="header">
          <h4>Au menu {items && items.map(item => item.name)}</h4>
        </div>
        <ul className="products">
          {items &&
            items.map(item => (
              <li key={item.name}>
                <h5>Formule par personne</h5>
                <p className="variety">{item.description}</p>
              </li>
            ))}
        </ul>
      </div>
    </StyledItemList>
  )
}

const StyledItemList = styled.div`
  .header {
    display: flex;
    align-items: center;
  }

  .picture {
    margin-left: 7px;
    position: relative;
    height: 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 50px;
    align-self: center;
    box-shadow: 1px 5px 20px 0px rgba(0, 0, 0, 0.1);

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50px;
      box-shadow: inset 0 0 30px 0px rgba(150, 150, 150, 0.3);
    }
  }

  h4 {
    font-family: Bely;
    margin-right: auto;
    font-size: 37px;
    font-weight: normal;
    color: ${colors.brand.black};
  }
  ul {
    margin: 20px 0;
  }
  li {
    margin: 18px 0;
  }

  h5 {
    font-size: 15px;
    margin-bottom: 4px;
    color: ${colors.brand.normal};
  }
  .variety {
    white-space: pre-line;

    .bullet {
      font-size: 16px;
      color: hsla(0, 0%, 0%, 0.8);
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .wrapper {
      padding: 0 25px;

      h4 {
        font-size: 22px;
      }
    }
  }
`

export default ItemList
export { StyledItemList }
