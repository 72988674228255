import { useRef, useEffect } from 'react'
import { useWindowWidth } from './useWindowWidth'

export function useFocus({ from = 768 } = { from: 0 }) {
  const inputRef = useRef()
  const width = useWindowWidth()
  useEffect(() => {
    if (width >= from && inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])
  return inputRef
}
