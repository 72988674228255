const closingToDateStr = process.env.GATSBY_CLOSING_TO_DATE;

export const defaultExcludeDates = [0, 1, 2].map(i => {
    const d = new Date()
    d.setDate(d.getDate() + i)
    return d
  })

export const excludeDates: () => Date[] =() => {
    if (!closingToDateStr) return defaultExcludeDates;

    const today = new Date();
    const closingToDate = new Date(closingToDateStr);

    if (today >= closingToDate) return defaultExcludeDates;

    const differenceInTime = closingToDate.getTime() - today.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    const closeDates = [...Array(differenceInDays).keys()].map(i =>{
        const d = new Date();
        d.setDate(d.getDate() + i)
        return d
    })

    return [...defaultExcludeDates, ...closeDates]
}


