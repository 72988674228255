import React, { memo, useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { includes } from 'lodash'
import { Button } from '@monbanquet/crumble'
import MailchimpForm from './MailchimpForm'
import { ModalContext } from '../components/context/ModalContext'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from '../components/Link'
import { EventContext } from './context/EventContext'
import { globalHistory } from '@reach/router'
import { track } from '../analytics'

const MenuAvailabilityModalWithData = memo(props => (
  <StaticQuery
    query={graphql`
      query {
        deliveryZipcodes: allDeliveryZipCode {
          edges {
            node {
              code
            }
          }
        }
        menuZipcodes: allMenuZipCode {
          edges {
            node {
              code
            }
          }
        }
      }
    `}
    render={({ deliveryZipcodes, menuZipcodes }) => (
      <MenuAvailabilityModal
        deliveryZipcodes={deliveryZipcodes.edges.map(({ node }) => node.code)}
        menuZipcodes={menuZipcodes.edges.map(({ node }) => node.code)}
        {...props}
      />
    )}
  />
))
MenuAvailabilityModalWithData.displayName = 'MenuAvailabilityModalWithData'

const MenuAvailabilityModal = memo(
  ({
    deliveryZipcodes,
    menuZipcodes,
    menu,
    newAddress,
    isQuote,
    modalDismissed = () => {},
    ...props
  }) => {
    const [modalOpened, setModalOpened] = useState(false)
    const [modalContent, setModalContent] = useState({})
    const { dispatch: dispatchModal } = useContext(ModalContext)
    const {
      state: { address, zipcode },
      dispatch: dispatchEventCtx,
    } = useContext(EventContext)

    let cityZipOnly
    if (newAddress && newAddress.zip) {
      // we only check for whole cities (2 first digits)
      cityZipOnly = parseInt(`${`${newAddress.zip}`.substring(0, 2)}000`)
    }

    const dismissModal = () => {
      setModalOpened(false)
      // reset address to either previous one in context or to null if previous one is the same
      const previousAddress =
        cityZipOnly === zipcode
          ? null
          : {
              ...address,
              // add timestamp to force uncontrolled components with a key to reset
              updatedAt: new Date(),
            }

      dispatchEventCtx({
        type: 'UPDATE',
        payload: { address: previousAddress },
      })
      modalDismissed()
    }

    const updateAddressAndZip = (a, z) => {
      dispatchEventCtx({
        type: 'UPDATE_ZIP',
        payload: { address: a, zipcode: z },
        analytics: { label: 'From_MenuAvailabilityModal' },
      })
    }

    useEffect(() => {
      if (!newAddress) {
        return
      }

      if (!newAddress.zip) {
        track('AddressNotFound', {
          category: `Page_${globalHistory.location.pathname}`,
        })
        setModalContent({
          title: `Nous n'avons pas réussi à trouver cette adresse`,
          content: (
            <>
              <p>{`Pourriez-vous vérifier que l'adresse est complète ?`}</p>
              <p>{`Peut-être manque-t'il simplement le numéro.`}</p>
            </>
          ),
          actions: (
            <Button className="cancel" onClick={dismissModal}>
              OK
            </Button>
          ),
        })
        setModalOpened(true)
        return
      }

      // if we don't deliver at all to this address
      if (!includes(deliveryZipcodes, cityZipOnly.toString())) {
        track('DeliveryUnavailable', {
          category: `Page_${globalHistory.location.pathname}`,
        })
        setModalContent({
          title: `Monbanquet.fr n'est pas encore arrivé à cette adresse`,
          content: (
            <p>{`Indiquez votre adresse email pour être tenu au courant de l'ouverture de notre service dans votre ville\u00A0!`}</p>
          ),
          actions: <MailchimpForm />,
        })
        setModalOpened(true)
      }
      // if we don't do menus for this zipcode
      else if (!isQuote && !includes(menuZipcodes, cityZipOnly.toString())) {
        track('NoMenusAvailable', {
          category: `Page_${globalHistory.location.pathname}`,
        })
        setModalContent({
          title: `Nous livrons à cette adresse uniquement sur devis`,
          content: null,
          actions: (
            <>
              <Button className="cancel" onClick={dismissModal}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  dispatchModal({
                    type: 'OPEN',
                    analytics: { label: 'From_MenuAvailabilityModal' },
                  })
                  dismissModal()
                }}
              >
                Demander un devis
              </Button>
            </>
          ),
        })
        setModalOpened(true)
      }
      // if the new address does not have this menu available
      else if (
        menu &&
        menu.zoneGeo &&
        menu.category &&
        !includes(menu.zoneGeo, cityZipOnly.toString())
      ) {
        track('MenuUnavailable', {
          category: `Page_${globalHistory.location.pathname}`,
        })
        setModalContent({
          title: `Ce menu n'est pas disponible à l'adresse sélectionnée`,
          content: (
            <>
              <p>{`Voulez-vous consulter les menus pour l'adresse suivante\u00A0?`}</p>
              <p className="new-address">
                {newAddress && newAddress.formatted}
              </p>
            </>
          ),
          actions: (
            <>
              <Button className="cancel" onClick={dismissModal}>
                Annuler
              </Button>
              <Button
                onClick={() => {
                  updateAddressAndZip(newAddress, cityZipOnly)
                  navigate(`/${menu.category}/${cityZipOnly}`)
                }}
              >
                Voir les menus
              </Button>
            </>
          ),
        })
        setModalOpened(true)
      } else {
        updateAddressAndZip(newAddress, cityZipOnly)
      }
    }, [newAddress])

    return (
      <StyledMenuAvailabilityModal
        opened={modalOpened}
        dismiss={dismissModal}
        title={modalContent.title}
        {...props}
      >
        {modalContent.content}
        <div className="actions">{modalContent.actions}</div>
      </StyledMenuAvailabilityModal>
    )
  },
)

const StyledMenuAvailabilityModal = memo(styled(Modal)``)

export default MenuAvailabilityModalWithData
export { StyledMenuAvailabilityModal }
