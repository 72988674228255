import React, { forwardRef, useContext } from 'react'
import styled from 'styled-components'
import { AddressInput } from '@monbanquet/crumble'
import { EventContext } from './context/EventContext'

const AddressField = (
  { onAddressSelected, onEmptyField = () => {}, ...props },
  ref,
) => {
  const {
    state: { address },
    dispatch,
  } = useContext(EventContext)

  return (
    <StyledAddressField
      ref={ref}
      /*
        uncontrolled with a key to reset on address change:
        https://reactjs.org/docs/uncontrolled-components.html#default-values
        https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
      */
      // add updatedAt to key to change it on reset (when address don't change)
      key={`${address && address.formatted}-${address && address.updatedAt}`}
      defaultValue={(address && address.formatted) || ''}
      apiKey={process.env.GATSBY_GOOGLE_MAPS_KEY}
      mapOptions={{
        componentRestrictions: { country: 'fr' },
        types: ['address'],
      }}
      secondary
      onChange={v => {
        if (v.trim().length === 0) {
          dispatch({
            type: 'RESET_ADDRESS',
          })
          onEmptyField()
        }
      }}
      onAddressSelected={onAddressSelected}
      {...props}
    />
  )
}

const StyledAddressField = styled(AddressInput)``

export default forwardRef(AddressField)
export { StyledAddressField }
