import {
  Button,
  StyledButton,
  VerticalDrawer,
  StyledVerticalDrawer,
} from '@monbanquet/crumble'
import { graphql } from 'gatsby'
import { find, groupBy, sortBy, union } from 'lodash'
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Link, { navigate } from '../components/Link'
import MenuForm, { StyledMenuForm } from '../components/MenuForm'
import ProductList from '../components/ProductList'
import { EventContext } from '../components/context/EventContext'
import { ModalContext } from '../components/context/ModalContext'
import { breakpoints, colors, layout, homeLayout } from '../theme'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import prependProtocol from '../util/prepend-protocol'
import MenuAvailabilityModal from '../components/MenuAvailabilityModal'
import { StyledProfileDropdown } from '../components/ProfileDropdown'
import TileList, { StyledTileList } from '../components/TileList'
import FullTileFluid from '../components/FullTileFluid'
import ArrowWhite from '../assets/arrow-white.svg'
import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxShape, { StyledParallaxShape } from '../components/ParallaxShape'
import MenusGrid from '../components/MenusGrid'
import Banner from '../components/banners/Banner'
import BottomBanner from '../components/banners/BannerBottom'
import Img from 'gatsby-image'
import serviceIcon from '../assets/icons/service.svg'
import ItemList from '../components/ItemList'

// https://react-slick.neostack.com/docs/api#settings
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1.999,
  lazyLoad: 'progressive',
  swipeToSlide: true,
  draggable: false,
  mobileFirst: true,
  // adaptiveHeight: true,
  responsive: [
    {
      breakpoint: breakpoints.desktop + 1,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: breakpoints.tablet + 1,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
}

const navigateToVariant = (menuVariants, nbGuests, menu) => {
  const variant = find(
    menuVariants.edges,
    mv => mv.node.nbGuests === nbGuests && menu.menuName === mv.node.menuName,
  )
  navigate(`/menus/${variant.node.fileName}`, { replace: true })
}

const getLargerVariant = menuVariants =>
  menuVariants.edges
    .map(({ node }) => node)
    .reduce(
      (larger, current) =>
        current.nbGuests > larger.nbGuests ? current : larger,
      { nbGuests: 0 },
    )

const MenuPage = ({
  data: { menu, menuVariants = { edges: [] }, category, menus, homePage },
  pageContext,
}) => {
  const {
    state: { nbGuests, zipcode, address },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const { dispatch: dispatchModal } = useContext(ModalContext)

  // check if there is a mismatch between the zipcode in context and the menu zipcode we're on
  const [newAddress, setNewAddress] = useState(address)
  const [drawerOpened, setDrawerOpened] = useState(false)

  useEffect(() => {
    dispatchEventCtx({
      type: 'UPDATE',
      payload: {
        category: menu.category,
      },
    })
  }, [])

  // TODO: can optimize because context re-render component and MenuPage does not need it
  // for nbGuests. Only MenuForm needs it. But like that it's more consistent with category-page
  // No early-optimisation!
  const menuVariantsGroupedByNbGuests = groupBy(
    menuVariants.edges,
    'node.nbGuests',
  )

  const nbGuestList = union(
    [nbGuests],
    Object.keys(menuVariantsGroupedByNbGuests).map(k => parseInt(k)),
  ).sort((a, b) => a - b)

  const pageTitle = `${menu.menuName} • ${category.title} • ${process.env.GATSBY_NAME}`

  let pictureTiles = []
  if (menu.mainPhotoTransformed) {
    pictureTiles = [
      {
        id: menu.mainPhotoTransformed,
        picture: menu.mainPhotoTransformed.childImageSharp,
      },
    ]
    menu.productsPhotosTransformed.forEach(item => {
      pictureTiles.push({
        id: item.id,
        picture: item.childImageSharp,
      })
    })
  }

  const menusGroupedByNbGuests = groupBy(menus.edges, 'node.nbGuests')
  const defaultNbGuests = 30

  const matchingMenus = sortBy(
    menusGroupedByNbGuests[defaultNbGuests],
    'node.sellingPricePerPerson',
  )

  return (
    <StyledMenuPage>
      <Helmet
        title={pageTitle}
        meta={[
          // { name: 'description', content: menu.menuDescription },
          { property: 'og:title', content: pageTitle },
          // { property: 'og:description', content: menu.menuDescription },
          {
            property: 'og:image',
            // content: prependProtocol(
            //   (menu.mainPhotoTransformed &&
            //     menu.mainPhotoTransformed.childImageSharp.fluid.originalImg) ||
            //     pageContext.defaultCategoryPic.fluid.src,
            // ),
          },
        ]}
        // set a canonical link to only the bigger variant of a menu to avoid duplicate content
        link={[
          {
            rel: 'canonical',
            href: `${process.env.GATSBY_URL}/menus/${
              getLargerVariant(menuVariants).fileName
            }`,
          },
        ]}
      />
      <Navbar />
      <ParallaxProvider>
        <div className="wrapper page-content">
          <ParallaxShape
            x={45}
            y={-18}
            shapeStyle={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              border: '10px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={35}
            y={-12}
            shapeStyle={{
              width: '35px',
              height: '19px',
              border: '10px solid #030101',
              borderBottom: 'none',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
            }}
          />
          <div className="content">
            <div className="menu-header">
              <h1>
                {menu.menuName}
                <Link to={`/${menu.category}/${zipcode}`} className="back-btn">
                  <img src={ArrowWhite} alt="back" /> Retour
                </Link>
              </h1>
              <h3>{menu.menuDescription}</h3>
              <div className="pictures-container">
                {menu.mainPhotoTransformed ? (
                  <TileList
                    settings={{
                      ...settings,
                      slidesToShow: 2,
                    }}
                    tiles={pictureTiles}
                    renderTile={<FullTileFluid />}
                  />
                ) : (
                  pageContext.defaultCategoryPic && (
                    <Img
                      fluid={pageContext.defaultCategoryPic.fluid}
                      alt={pageContext.defaultCategoryPic.title}
                      style={imgStyle}
                      loading="lazy"
                    />
                  )
                )}
              </div>
            </div>
            <div className="quantity-notice">
              <img src={serviceIcon} alt="service" loading="lazy" />
              {`Diversité et quantités adaptées pour un ${category.title.toLowerCase()} de ${nbGuests} personnes`}
            </div>
            {menu.category == 'lunchbox' ? (
              <ItemList items={menu.items} nbGuest={nbGuests} />
            ) : (
              <ProductList products={menu.products} nbGuest={nbGuests} />
            )}
          </div>
          <div className="form">
            <VerticalDrawer
              opened={drawerOpened}
              onDraw={setDrawerOpened}
              handleSize={60}
            >
              <MenuForm
                menu={menu}
                className={drawerOpened ? 'opened' : ''}
                //nbGuestOptions={nbGuestList}
                onNbGuestsChange={v => {
                  if (menu.category == 'lunchbox') {
                    dispatchEventCtx({
                      type: 'UPDATE_GUESTS',
                      payload: { nbGuests: parseInt(v) },
                      analytics: { label: 'From_SearchForm' },
                    })
                  } else {
                    //navigateToVariant(menuVariants, v, menu)
                  }
                }}
                onAddressSelected={setNewAddress}
                onChooseMenu={m => {
                  dispatchEventCtx({
                    type: 'UPDATE_MENU',
                    payload: { menu: m },
                    analytics: { label: 'From_MenuPage' },
                  })
                  navigate('/commande')
                }}
              />
            </VerticalDrawer>
          </div>
        </div>
        <div className="bottom">
          <div className="other-menus-container">
            <h2>Nos autres {menu.category}</h2>
            <h3>Les autres {menu.category} à la carte pour vos événements</h3>
            <MenusGrid
              menus={matchingMenus}
              category={category}
              pageContext={pageContext}
            />
          </div>
          <Banner {...homePage.banner2} />
          <BottomBanner bottomBanner={homePage.bottomCta} />
          <Footer />
        </div>
      </ParallaxProvider>
      <MenuAvailabilityModal newAddress={newAddress} menu={menu} />
    </StyledMenuPage>
  )
}

const imgStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const StyledMenuPage = styled.div`
  background-color: ${colors.background.light};

  ${StyledTileList} {
    .slick-arrow {
      height: 46px;
      width: 46px;

      &:before {
        background-size: 24px 24px;
      }

      &.slick-prev {
        left: 15px;
      }

      &.slick-next {
        right: 95px;
      }
    }

    .slick-list {
      overflow: hidden;

      .slick-track {
        margin-left: 0;

        img {
          width: 100%;
        }
      }
    }
  }
  .page-content {
    padding-top: ${layout.navbarHeight.mobile}px;
    position: relative;
  }

  > .wrapper {
    max-width: ${homeLayout.width};
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  ${StyledVerticalDrawer} {
    top: ${layout.navbarHeight.mobile}px;
  }

  @media (min-width: 1450px) {
    .page-content {
      padding-top: 120px;
    }
    ${StyledVerticalDrawer} {
      top: 120px;
    }
    > .wrapper {
      padding-left: 5%;
    }
    .menu-header {
      width: 750px;
    }
  }

  ${StyledProfileDropdown} {
    display: none;
  }

  .content {
    flex-basis: 65%;
    z-index: 1;
  }
  .form {
    flex-basis: 35%;
    flex-shrink: 0;
    z-index: 1;
  }

  .menu-header {
    h1 {
      margin-bottom: 15px;
      font-size: 3rem;
      color: ${colors.brand.normal};
      display: flex;
      word-break: break-word;

      .back-btn {
        font-family: Centra;
        margin: 5px 0 5px auto;
        background-color: ${colors.brand.green};
        border-radius: 30px;
        font-size: 13px;
        color: white;
        padding: 5px 23px;
        display: flex;
        align-items: center;
        max-height: 40px;
        min-height: 33px;
        min-width: 130px;

        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }

    h3 {
      font-family: Centra;
      color: ${colors.brand.black};
      font-weight: normal;
      font-size: 17px;
      margin-bottom: 40px;
      max-width: 700px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      .pictures-container {
        height: 300px;
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;

        ${StyledTileList} {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: -80px;
        }
      }
    }
  }

  .quantity-notice {
    display: flex;
    align-items: center;
    border: 1px solid rgba(129, 129, 129, 0.24);
    border-radius: 3px;
    padding: 5px;
    justify-content: center;
    color: ${colors.text.normal};
    margin: 30px 0;

    img {
      width: 35px;
      margin-right: 20px;
    }
  }

  .bottom {
    position: relative;
    padding-top: 80px;
    background-color: ${colors.background.light};
    z-index: 2;
    overflow: hidden;

    .other-menus-container {
      h2 {
        font-family: Bely;
        text-align: center;
        font-size: 37px;
        color: ${colors.brand.normal};
        font-weight: normal;
        line-height: 1.4;
      }

      h3 {
        font-family: Centra;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

  ${StyledButton}.btn-link {
    background: none;
    border: none;
    outline: none;
    color: ${colors.brand.normal};
    text-decoration: underline;

    &:hover,
    &:focus {
      box-shadow: none;
      text-decoration: none;
    }

    &:active {
      background: none;
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .content {
      flex-basis: 55%;
    }
    .form {
      flex-basis: 35%;
    }
    > .wrapper {
      max-width: ${homeLayout.smallWidth};
    }
    ${StyledParallaxShape} {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.tablet + 1}px) {
    ${StyledVerticalDrawer} {
      position: sticky;
      top: 80px;

      nav {
        position: static;
        transform: translate3d(0, 0, 0) !important;
      }
      .overlay {
        display: none !important;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    > .wrapper {
      flex-direction: column;
    }
    .menu-header {
      text-align: center;

      h1 {
        font-size: 33px;
        flex-direction: column-reverse;

        .back-btn {
          font-family: Centra;
          margin: -10px 20px 30px auto;
          padding: 5px 17px;
          margin-bottom: 30px;

          img {
            width: 20px;
            margin-right: 10px;
          }
        }
      }

      h3 {
        font-family: Centra;
        color: ${colors.brand.black};
        font-weight: normal;
        font-size: 15px;
        margin-bottom: 40px;
        max-width: 700px;
      }

      .pictures-container {
        height: unset;
        position: relative;
        margin-bottom: unset;
        overflow: unset;

        ${StyledTileList} {
          position: unset;
        }
      }
    }
    .bottom {
      padding-top: 60px;

      .other-menus-container {
        h2 {
          font-size: 26px;
          line-height: 1;
        }

        h3 {
          font-size: 16px;
          margin: 20px 20px 0;
        }
      }
    }
    .form {
      z-index: 3;
    }
    ${StyledVerticalDrawer} {
      nav {
        background: #f5f5f5;
        bottom: 0;
        left: 0;
      }
      .handle {
        height: 60px;
        top: 0;
        cursor: n-resize;
      }

      &.opened-drawer .handle {
        cursor: s-resize;
      }

      .overlay {
        cursor: s-resize;
      }
    }

    ${StyledMenuForm} {
      position: static;
      margin-left: 0;
      padding: 0 20px 20px;
      width: 100%;
      border: none;

      &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: url() no-repeat center 7px;
      }

      &.opened {
        box-shadow: none;

        .title img {
          transform: rotate(180deg);
        }
      }
    }

    .custom-menu {
      position: static;
      margin-left: 0;
      padding-bottom: 100px;
      width: 100%;
      p {
        margin-top: 0;
      }
      .hint {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    > .wrapper {
      flex-direction: column;
    }
  }
`

export default MenuPage
export { StyledMenuPage }

export const query = graphql`
  query($fileName: String!, $category: String!, $menuId: String!) {
    # Used to get the title of the page from the slug
    category: contentfulEventTypePage(slug: { eq: $category }) {
      title
    }
    # Used to compute all the variants that goes into the MenuForm Selects
    menuVariants: allMenu(filter: { menuId: { eq: $menuId } }) {
      edges {
        node {
          fileName
          menuId
          menuName
          nbGuests
          zoneGeo
        }
      }
    }
    # The menu of the page we're in
    menu: menu(fileName: { eq: $fileName }) {
      fileName
      menuId
      menuName
      menuDescription
      sellingPricePerPerson
      sellingPriceExcludingTax
      tvaAmount
      sellingPriceIncludingTax
      category
      products {
        id
        name
        variety
        quantity
        supplier
      }
      mainPhoto {
        thumbnails {
          large {
            url
          }
        }
      }
      mainPhotoTransformed {
        name
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            originalName
            sizes
            originalImg
            originalName
          }
          original {
            height
            width
            src
          }
        }
      }
      productsPhotosTransformed {
        id
        name
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            originalName
            sizes
            originalImg
            originalName
          }
          original {
            height
            width
            src
          }
        }
      }
      nbGuests
      versionId
      zoneGeo
      openingDays
    }
    menus: allMenu(
      filter: { category: { eq: $category }, visibleOnWebsite: { eq: true } }
    ) {
      edges {
        node {
          fileName
          menuId
          menuName
          menuDescription
          sellingPricePerPerson
          nbGuests
          products {
            id
            name
          }
          mainPhotoTransformed {
            name
            childImageSharp {
              fluid {
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
    homePage: contentfulHomePage(fixedId: { eq: "homepage-1" }) {
      banner1 {
        ...BannerFragment
      }
      banner2 {
        ...BannerFragment
      }
      banner3 {
        ...BannerFragment
      }
      banner4 {
        ...BannerFragment
      }
      bottomCta {
        ...CallToActionFragment
      }
    }
  }
`
